import request from "@/utils/http/requset";

export function getReportTbApi(data) {
    return request({
        url: "/manage/report/recharge/m10",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function getReportBigTbApi(data) {
    return request({
        url: "/manage/report/recharge/b10",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

export function getReportDayTbApi(data) {
    return request({
        url: "/manage/report/recharge/day",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}


export function getEachartsApi(data) {
    return request({
        url: "/manage/report/recharge/search",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}
// export default {
//     getReportTbApi,
//     getReportBigTbApi,
//     getReportDayTbApi,
// };
