

import { getEachartsApi } from "../../../api/report/index";
import xData from "./data.js";
export default {
  data() {
    return {
      eachartData: {},
      dialogVisible: false,
      field: "",
      fieldName: "",
      xData: xData
    };
  },
  methods: {
    headerCellClassName({ column }) {
      if (
        column.property != "rptTime" &&
        column.property != "merchantId" &&
        column.property != "userType"
      ) {
        return "table_header";
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    search(obj) {
      this.getEachart(this.field, obj.days, obj.userType)
    },
    headerClick(column) {
      console.log(column.property);
      if (
        column.property != "rptTime" &&
        column.property != "merchantId" &&
        column.property != "userType"
      ) {
        this.field = column.property;
        this.fieldName = column.label;
        this.$refs.dialogEachart.days = 3;
        this.$refs.dialogEachart.userType = 0;
        this.getEachart(column.property, 3, 0);
      }
    },
    async getEachart(field, days, userType) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dialogEachart.loading = true;
      });
      let res = await getEachartsApi({
        content: {
          field: field,
          days: days,
          userType: userType
        }
      });

      if (res.code == "0000") {
        let keys = Object.keys(res.data);
        let legendData = []
        let seriesData = []
        keys.forEach(item => {
          let time = item[0] + item[1] + "-" + item[2] + item[3]
          legendData.push(time)
        })

        keys.forEach(item => {
          let data = [];
          xData.forEach(time => {
            let v = 0;
            let t = time.split(":")
            res.data[item].forEach(i => {
              if (i.time == (t[0] + t[1])) {
                v = i.value
              }
            })
            data.push(v)
          })

          seriesData.push({
            name: item[0] + item[1] + "-" + item[2] + item[3],
            type: "line",
            data: data,
          })
        })
        this.eachartData.legendData = legendData;
        this.eachartData.seriesData = seriesData;
        this.eachartData.title = this.fieldName;
        console.log(this.eachartData);
        this.$nextTick(() => {
          this.$refs.dialogEachart.init();
        });
      }
    },
  },
};


