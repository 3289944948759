<template>
  <el-dialog
    :destroy-on-close="true"
    :visible="dialogVisible"
    width="60%"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @before-close="close"
  >
    <div v-loading="loading">
      <div style="display: flex">
        <el-radio-group v-model="days" @input="daysChange">
          <el-radio-button label="3">3天</el-radio-button>
          <el-radio-button label="7">7天</el-radio-button>
        </el-radio-group>
        <div style="width: 20px"></div>
        <el-radio-group v-model="userType" @input="userTypeChange">
          <el-radio-button label="0">新客</el-radio-button>
          <el-radio-button label="1">老客</el-radio-button>
        </el-radio-group>
      </div>
      <div ref="echartDom" class="echart-dom"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["dialogVisible", "data", "xData"],
  data() {
    return {
      echartNewInit: null,
      echartOldInit: null,
      days: 3,
      userType: 0,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    close() {
      if (this.echartInit) {
        this.echartInit.dispose();
      }
      this.$emit("closeDialog");
    },
    init() {
      this.echartInit = echarts.init(this.$refs.echartDom);
      const option = {
        title: {
          text: this.data.title,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.data.legendData,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            rotate: 45, // 坐标标签倾斜45度显示
          },
          data: this.xData,
        },
        yAxis: {
          type: "value",
        },
        series: this.data.seriesData,
      };

      this.echartInit.setOption(option);
      this.loading = false;
    },
    daysChange(value) {
      this.days = value;
      this.$emit("search", { days: this.days, userType: this.userType });
    },
    userTypeChange(value) {
      this.userType = value;
      this.$emit("search", { days: this.days, userType: this.userType });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.echart-dom {
  width: 100%;
  height: 500px;
}
</style>
